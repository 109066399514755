import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import LDApp from "./LDApp";
import { setContext } from "@apollo/client/link/context";
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from "@apollo/client/link/error";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as cognito from './libs/cognito';
import { HandledGQLErrors } from "./utils/errorHandling/HandledGQLErrors";

const GRAPHQL_URL = process.env['REACT_APP_SERVER_ENDPOINT'];
// const SUBSCRIPTION_URL= GRAPHQL_URL?.replace(/(http)(s)?:\/\//, "ws$2://");

if (!GRAPHQL_URL 
    // || !SUBSCRIPTION_URL
    ) {
    throw new Error('Missing GraphQL endpoint');
}

const handledGQLErrors = HandledGQLErrors.getInstance();
const error = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(error => {

            let isErrorHandledLocally = false;
            if(error.extensions != undefined){
                const exceptionName = error.extensions['exception']?.name;
                const errorCode = error.extensions['code'];

                if(exceptionName != undefined && errorCode != undefined){
                    isErrorHandledLocally = handledGQLErrors.isGQLErrorHandled(exceptionName, errorCode);
                }
            }

            if(!isErrorHandledLocally){
                console.log(
                        `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`, error
                );
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
            }
            }
        );
        toast.clearWaitingQueue();
    }

    if (networkError) {
        // https://stackoverflow.com/questions/49343024/getting-typeerror-failed-to-fetch-when-the-request-hasnt-actually-failed
        const msg = `[Network error]: ${networkError}`;
        console.log(networkError);
        if (!msg.includes("Failed to fetch")) {
            toast.error(msg, {
                position: toast.POSITION.TOP_CENTER
            });
            toast.clearWaitingQueue();
        }
    }
});

// const wsLink = new WebSocketLink({
//     uri: SUBSCRIPTION_URL,
//     options: {
//         reconnect: true,
//         connectionParams: {
//             authorization: cognito.getIdToken() ? `Bearer ${cognito.getIdToken()}` : ''
//         },
//     }
// });

const httpLink = createHttpLink({
    uri: GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: cognito.getIdToken() ? `Bearer ${cognito.getIdToken()}` : ''
        }
    };
});

// const splitLink = split(
//     ({ query }) => {
//       const definition = getMainDefinition(query);
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       );
//     },
//     // wsLink,
//     httpLink,
//   );

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: error.concat(authLink).concat(httpLink),
    defaultOptions: {
        mutate: { errorPolicy: 'ignore' },
    },
    cache: new InMemoryCache({
        typePolicies: {
            WorksheetAssumptionLine: {
                fields: {
                    values: {
                        merge(_, incoming: any[]) {
                            return [...incoming];
                        }
                    }
                }
            }
        }
    })
});

ReactDOM.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <ToastContainer autoClose={5000} limit={2}/>
                <LDApp />
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root'),
);
